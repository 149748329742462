<template>
  <div class="help-screen">
    <h1>{{ pages[currentPage].title }}</h1>
    
    <p>{{ pages[currentPage].content }}</p>
    <img v-if="pages[currentPage].imgUrl" :src="pages[currentPage].imgUrl">
    <div class="navigation">
      <button @click="changeHelpPage(-1)"><i class="fas fa-chevron-left"></i></button>
      <p>{{ currentPage+1 }} / {{ pages.length }}</p>
      <button @click="changeHelpPage(1)"><i class="fas fa-chevron-right"></i></button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: 0
    }
  },
  props: {
    pages: Array
  },
  methods: {
    changeHelpPage(direction) {
      if (direction < 0) {
        if (this.currentPage > 0) this.currentPage--;
      } else {
        if (this.currentPage < this.pages.length-1) this.currentPage++;
      }
    }
  }
}
</script>

<style scoped>
.help-screen {
  background: #fff;
  border: 3px solid #F0A824;
  box-shadow: 0 0 20px 0 #000;
  position: fixed;
  top: calc(50px + 1rem);
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 999;
  font-family: 'Oswald', sans-serif;
}
.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navigation p {
  font-weight: bold;
}
.navigation button {
  margin: 0;
  box-shadow: none;
}
img {
  max-height: calc(100% - 120px);
}
</style>